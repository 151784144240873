import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { navigate } from 'gatsby';
import { Icon } from 'react-icons-kit';
import { checkmarkCircled } from 'react-icons-kit/ionicons/checkmarkCircled';
import Text from 'common/src/components/Text';
import Heading from 'common/src/components/Heading';
import Button from 'common/src/components/Button';
import Container from 'common/src/components/UI/Container';
import SectionWrapper, {
  ButtonGroup,
  PricingArea,
  InnerWrapper,
  PricingCard,
} from './pricingPolicy.style';
import { updateUserInfo } from '../../../actions';
import multifamilyImage from 'common/src/assets/image/crypto/blueMultifamily.png';
import blueSingleFamily from 'common/src/assets/image/crypto/blueSingleFamily.png';
import blueLand from 'common/src/assets/image/crypto/blueLand.png';
import blueHospital from 'common/src/assets/image/crypto/blueHospital.png';
import officeImage from 'common/src/assets/image/crypto/blueOffice.png';
import blueIndustrial from 'common/src/assets/image/crypto/blueIndustrial.png';

import { pricing } from 'common/src/data/Crypto';

const OurProductsPropertyTypeSquares = ({ currentUser }) => {
  const { slogan, title, monthly, annualy } = pricing;
  const [state, setState] = useState({
    active: 'monthly',
    pricingPlan: monthly,
  });

  //so images all line up
  useEffect(() => {
    const adjustH3Size = () => {
      const h3Elements = document.querySelectorAll('.card-header h3');
      let maxHeight = 0;

      h3Elements.forEach((h3) => {
        const height = h3.offsetHeight;
        if (height > maxHeight) {
          maxHeight = height;
        }
      });

      h3Elements.forEach((h3) => {
        h3.style.height = `${maxHeight}px`;
      });
    };

    adjustH3Size();
    window.addEventListener('resize', adjustH3Size);

    return () => {
      window.removeEventListener('resize', adjustH3Size);
    };
  }, []);

  const goToBuy = async (e) => {
    navigate('/buy-pages/buy-process/');
  };

  const goToRefi = async (e) => {
    navigate('/refi-pages/refinance-reasons/');
  };

  const goToPurchaseOrRefi = async (e) => {
    navigate('/buyOrRefi/');
  };

  const goToHeloc = async (e) => {
    navigate('/heloc/');
  };

  return (
    <SectionWrapper id="pricing">
      <Container className="containerClass">
        <PricingArea>
          <Heading
            content="Property Types"
            {...title}
            style={{ textAlign: 'center' }}
            fontSize="32px"
          />
          <InnerWrapper>
            <PricingCard>
              <div
                className="card-header"
                style={{ textAlign: 'center', marginBottom: '0' }}
              >
                <Heading as="h3" content={`Single Family`} />
                <img
                  src={blueSingleFamily}
                  alt="Multifamily"
                  style={{ width: '100px', margin: '10px 0' }}
                />
              </div>
              <div
                className="card-body"
                style={{ textAlign: 'center', marginBottom: '0' }}
              >
                <ul className="feature-list">
                  <li
                    key={`${state.active}-feature--key${2}`}
                    style={{ marginBottom: '0' }}
                  >
                    <Icon icon={checkmarkCircled} />
                    Owner occupied
                  </li>
                </ul>
              </div>
              <div className="card-footer" style={{ marginTop: '0' }}>
                <Button title={'Learn more'} onClick={goToPurchaseOrRefi} />
              </div>
            </PricingCard>
            <PricingCard>
              <div
                className="card-header"
                style={{ textAlign: 'center', marginBottom: '0' }}
              >
                <Heading as="h3" content={`Multifamily`} />
                <img
                  src={multifamilyImage}
                  alt="Multifamily"
                  style={{ width: '100px', margin: '10px 0' }}
                />
              </div>
              <div
                className="card-body"
                style={{ textAlign: 'center', marginBottom: '0' }}
              >
                <ul className="feature-list">
                  <li
                    key={`${state.active}-feature--key${2}`}
                    style={{ marginBottom: '0' }}
                  >
                    <Icon icon={checkmarkCircled} />
                    5+ units
                  </li>
                </ul>
              </div>
              <div className="card-footer" style={{ marginTop: '0' }}>
                <Button title={'Learn more'} onClick={goToPurchaseOrRefi} />
              </div>
            </PricingCard>
            <PricingCard>
              <div
                className="card-header"
                style={{ textAlign: 'center', marginBottom: '0' }}
              >
                <Heading as="h3" content={`Office/Business`} />
                <img
                  src={officeImage}
                  alt="Multifamily"
                  style={{ width: '103px', margin: '10px 0' }}
                />
              </div>
              <div
                className="card-body"
                style={{ textAlign: 'center', marginBottom: '0' }}
              >
                <ul className="feature-list">
                  <li
                    key={`${state.active}-feature--key${2}`}
                    style={{ marginBottom: '0' }}
                  >
                    <Icon icon={checkmarkCircled} />
                    Wide range of office options
                  </li>
                </ul>
              </div>
              <div className="card-footer" style={{ marginTop: '0' }}>
                <Button title={'Learn more'} onClick={goToPurchaseOrRefi} />
              </div>
            </PricingCard>
          </InnerWrapper>
        </PricingArea>
        <PricingArea>
          <InnerWrapper>
            <PricingCard>
              <div
                className="card-header"
                style={{ textAlign: 'center', marginBottom: '0' }}
              >
                <Heading as="h3" content={`Land`} />
                <img
                  src={blueLand}
                  alt="Multifamily"
                  style={{ width: '95px', margin: '10px 0' }}
                />
              </div>
              <div
                className="card-body"
                style={{ textAlign: 'center', marginBottom: '0' }}
              >
                <ul className="feature-list">
                  <li
                    key={`${state.active}-feature--key${2}`}
                    style={{ marginBottom: '0' }}
                  >
                    <Icon icon={checkmarkCircled} />
                    Bare land and planned construction
                  </li>
                </ul>
              </div>
              <div className="card-footer" style={{ marginTop: '0' }}>
                <Button title={'Learn more'} onClick={goToPurchaseOrRefi} />
              </div>
            </PricingCard>
            <PricingCard>
              <div
                className="card-header"
                style={{ textAlign: 'center', marginBottom: '0' }}
              >
                <Heading as="h3" content={`Healthcare`} />
                <img
                  src={blueHospital}
                  alt="Multifamily"
                  style={{ width: '100px', margin: '10px 0' }}
                />
              </div>
              <div
                className="card-body"
                style={{ textAlign: 'center', marginBottom: '0' }}
              >
                <ul className="feature-list">
                  <li
                    key={`${state.active}-feature--key${2}`}
                    style={{ marginBottom: '0' }}
                  >
                    <Icon icon={checkmarkCircled} />
                    Hospitals, clinics, senior care, etc
                  </li>
                </ul>
              </div>
              <div className="card-footer" style={{ marginTop: '0' }}>
                <Button title={'Learn more'} onClick={goToPurchaseOrRefi} />
              </div>
            </PricingCard>
            <PricingCard>
              <div
                className="card-header"
                style={{ textAlign: 'center', marginBottom: '0' }}
              >
                <Heading as="h3" content={`Industrial`} />
                <img
                  src={blueIndustrial}
                  alt="Multifamily"
                  style={{ width: '110px', margin: '10px 0' }}
                />
              </div>
              <div
                className="card-body"
                style={{ textAlign: 'center', marginBottom: '0' }}
              >
                <ul className="feature-list">
                  <li
                    key={`${state.active}-feature--key${2}`}
                    style={{ marginBottom: '0' }}
                  >
                    <Icon icon={checkmarkCircled} />
                    Warehouses, storage, manufacturing, etc
                  </li>
                </ul>
              </div>
              <div className="card-footer" style={{ marginTop: '0' }}>
                <Button title={'Learn more'} onClick={goToPurchaseOrRefi} />
              </div>
            </PricingCard>
          </InnerWrapper>
        </PricingArea>
      </Container>
    </SectionWrapper>
  );
};

const mapStateToProps = ({ root: { currentUser } }) => ({
  currentUser: currentUser?.user,
  userToken: currentUser?.token,
});

const mapDispatchToProps = (dispatch) => {
  return {
    updateUserInfo: (currentUser, userToken) =>
      dispatch(updateUserInfo(currentUser, userToken)),
  };
};

const OurProductsPropertyTypeSquaresRedux = connect(
  mapStateToProps,
  mapDispatchToProps
)(OurProductsPropertyTypeSquares);

export default OurProductsPropertyTypeSquaresRedux;
