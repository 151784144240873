import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { navigate } from 'gatsby';
import { Icon } from 'react-icons-kit';
import { checkmarkCircled } from 'react-icons-kit/ionicons/checkmarkCircled';
import Text from 'common/src/components/Text';
import Heading from 'common/src/components/Heading';
import Button from 'common/src/components/Button';
import Container from 'common/src/components/UI/Container';
import SectionWrapper, {
  ButtonGroup,
  PricingArea,
  InnerWrapper,
  PricingCard,
} from './pricingPolicy.style';
import { updateUserInfo } from '../../../actions';
import multifamilyImage from 'common/src/assets/image/crypto/blueMultifamily.png';
import blueSingleFamily from 'common/src/assets/image/crypto/blueSingleFamily.png';
import blueLand from 'common/src/assets/image/crypto/blueLand.png';
import blueHospital from 'common/src/assets/image/crypto/blueHospital.png';
import officeImage from 'common/src/assets/image/crypto/blueOffice.png';
import blueIndustrial from 'common/src/assets/image/crypto/blueIndustrial.png';
import blueEquityCash from 'common/src/assets/image/crypto/blueEquityCash.png';
import blueConstruction from 'common/src/assets/image/crypto/blueConstruction.png';
import blueCMBSimage from 'common/src/assets/image/crypto/blueCMBSimage.png';
import bridgeImage from 'common/src/assets/image/crypto/bridgeImage.png';
import { pricing } from 'common/src/data/Crypto';

const OurProductsFinanceTypeSquares = ({ currentUser }) => {
  const { slogan, title, monthly, annualy } = pricing;
  const [state, setState] = useState({
    active: 'monthly',
    pricingPlan: monthly,
  });

  //so images all line up
  useEffect(() => {
    const adjustH3Size = () => {
      const h3Elements = document.querySelectorAll('.card-header h3');
      let maxHeight = 0;

      h3Elements.forEach((h3) => {
        const height = h3.offsetHeight;
        if (height > maxHeight) {
          maxHeight = height;
        }
      });

      h3Elements.forEach((h3) => {
        h3.style.height = `${maxHeight}px`;
      });
    };

    adjustH3Size();
    window.addEventListener('resize', adjustH3Size);

    return () => {
      window.removeEventListener('resize', adjustH3Size);
    };
  }, []);

  const goToBuy = async (e) => {
    navigate('/buy-pages/buy-process/');
  };

  const goToRefi = async (e) => {
    navigate('/refi-pages/refinance-reasons/');
  };

  const goToPurchaseOrRefi = async (e) => {
    navigate('/buyOrRefi/');
  };

  const goToFannieMaeMultifamily = async (e) => {
    navigate('/OurProducts/fannieMaeMultifamilyAboutPage/');
  };

  const goToHeloc = async (e) => {
    navigate('/heloc/');
  };

  return (
    <SectionWrapper id="pricing">
      <Container className="containerClass">
        <PricingArea>
          <Heading
            content="Financing Products"
            {...title}
            style={{ textAlign: 'center' }}
            fontSize="32px"
          />
          <InnerWrapper>
            <PricingCard>
              <div
                className="card-header"
                style={{ textAlign: 'center', marginBottom: '0' }}
              >
                <Heading as="h3" content={`1-4 unit conventional`} />
                <img
                  src={blueSingleFamily}
                  alt="Multifamily"
                  style={{ width: '100px', margin: '10px 0' }}
                />
              </div>
              <div
                className="card-body"
                style={{ textAlign: 'center', marginBottom: '0' }}
              >
                <ul className="feature-list">
                  <li
                    key={`${state.active}-feature--key${2}`}
                    style={{ marginBottom: '0' }}
                  >
                    <Icon icon={checkmarkCircled} />
                    Owner occupied low down payment options
                  </li>
                </ul>
              </div>
              <div className="card-footer" style={{ marginTop: '0' }}>
                <Button title={'Learn more'} onClick={goToPurchaseOrRefi} />
              </div>
            </PricingCard>
            <PricingCard>
              <div
                className="card-header"
                style={{ textAlign: 'center', marginBottom: '0' }}
              >
                <Heading as="h3" content={`1-4 unit FHA`} />
                <img
                  src={blueSingleFamily}
                  alt="Multifamily"
                  style={{ width: '100px', margin: '10px 0' }}
                />
              </div>
              <div
                className="card-body"
                style={{ textAlign: 'center', marginBottom: '0' }}
              >
                <ul className="feature-list">
                  <li
                    key={`${state.active}-feature--key${2}`}
                    style={{ marginBottom: '0' }}
                  >
                    <Icon icon={checkmarkCircled} />
                    More flexible credit requirements
                  </li>
                </ul>
              </div>
              <div className="card-footer" style={{ marginTop: '0' }}>
                <Button title={'Learn more'} onClick={goToPurchaseOrRefi} />
              </div>
            </PricingCard>
            <PricingCard>
              <div
                className="card-header"
                style={{ textAlign: 'center', marginBottom: '0' }}
              >
                <Heading as="h3" content={`1-8 unit DSCR rental income loan`} />
                <img
                  src={multifamilyImage}
                  alt="Multifamily"
                  style={{ width: '103px', margin: '10px 0' }}
                />
              </div>
              <div
                className="card-body"
                style={{ textAlign: 'center', marginBottom: '0' }}
              >
                <ul className="feature-list">
                  <li
                    key={`${state.active}-feature--key${2}`}
                    style={{ marginBottom: '0' }}
                  >
                    <Icon icon={checkmarkCircled} />
                    Qualify based on income from the property: DEBT SERVICE
                    COVERAGE RATIO (DSCR)
                  </li>
                </ul>
              </div>
              <div className="card-footer" style={{ marginTop: '0' }}>
                <Button title={'Learn more'} onClick={goToPurchaseOrRefi} />
              </div>
            </PricingCard>
          </InnerWrapper>
        </PricingArea>
        <PricingArea>
          <InnerWrapper>
            <PricingCard>
              <div
                className="card-header"
                style={{ textAlign: 'center', marginBottom: '0' }}
              >
                <Heading as="h3" content={`1-4 unit HELOC/HELOAN`} />
                <img
                  src={blueEquityCash}
                  alt="Multifamily"
                  style={{ width: '95px', margin: '10px 0' }}
                />
              </div>
              <div
                className="card-body"
                style={{ textAlign: 'center', marginBottom: '0' }}
              >
                <ul className="feature-list">
                  <li
                    key={`${state.active}-feature--key${2}`}
                    style={{ marginBottom: '0' }}
                  >
                    <Icon icon={checkmarkCircled} />
                    Fixed and Variable rate HELOCs and HELOANs
                  </li>
                </ul>
              </div>
              <div className="card-footer" style={{ marginTop: '0' }}>
                <Button title={'Learn more'} onClick={goToHeloc} />
              </div>
            </PricingCard>
            <PricingCard>
              <div
                className="card-header"
                style={{ textAlign: 'center', marginBottom: '0' }}
              >
                <Heading as="h3" content={`1-4 unit fix and flip/rehab`} />
                <img
                  src={blueSingleFamily}
                  alt="Multifamily"
                  style={{ width: '95px', margin: '10px 0' }}
                />
              </div>
              <div
                className="card-body"
                style={{ textAlign: 'center', marginBottom: '0' }}
              >
                <ul className="feature-list">
                  <li
                    key={`${state.active}-feature--key${2}`}
                    style={{ marginBottom: '0' }}
                  >
                    <Icon icon={checkmarkCircled} />
                    Get money for purchase and rehab finacing
                  </li>
                </ul>
              </div>
              <div className="card-footer" style={{ marginTop: '0' }}>
                <Button title={'Learn more'} onClick={goToPurchaseOrRefi} />
              </div>
            </PricingCard>

            <PricingCard>
              <div
                className="card-header"
                style={{ textAlign: 'center', marginBottom: '0' }}
              >
                <Heading as="h3" content={`Construction, rehab, repair`} />
                <img
                  src={blueConstruction}
                  alt="Multifamily"
                  style={{ width: '100px', margin: '10px 0' }}
                />
              </div>
              <div
                className="card-body"
                style={{ textAlign: 'center', marginBottom: '0' }}
              >
                <ul className="feature-list">
                  <li
                    key={`${state.active}-feature--key${2}`}
                    style={{ marginBottom: '0' }}
                  >
                    <Icon icon={checkmarkCircled} />
                    Ground up or further construction financing
                  </li>
                </ul>
              </div>
              <div className="card-footer" style={{ marginTop: '0' }}>
                <Button title={'Learn more'} onClick={goToPurchaseOrRefi} />
              </div>
            </PricingCard>
          </InnerWrapper>
        </PricingArea>
        <PricingArea>
          <InnerWrapper>
            <PricingCard>
              <div
                className="card-header"
                style={{ textAlign: 'center', marginBottom: '0' }}
              >
                <Heading
                  as="h3"
                  content={`Commercial Fannie Mae/Freddie Mac`}
                />
                <img
                  src={multifamilyImage}
                  alt="Multifamily"
                  style={{ width: '95px', margin: '10px 0' }}
                />
              </div>
              <div
                className="card-body"
                style={{ textAlign: 'center', marginBottom: '0' }}
              >
                <ul className="feature-list">
                  <li
                    key={`${state.active}-feature--key${2}`}
                    style={{ marginBottom: '0' }}
                  >
                    <Icon icon={checkmarkCircled} />
                    Lowest rate multifamily loans
                  </li>
                </ul>
              </div>
              <div className="card-footer" style={{ marginTop: '0' }}>
                <Button
                  title={'Learn more'}
                  onClick={goToFannieMaeMultifamily}
                />
              </div>
            </PricingCard>
            <PricingCard>
              <div
                className="card-header"
                style={{ textAlign: 'center', marginBottom: '0' }}
              >
                <Heading
                  as="h3"
                  content={`Commercial Mortgage Backed Securities`}
                />
                <img
                  src={blueCMBSimage}
                  alt="Multifamily"
                  style={{ width: '95px', margin: '10px 0' }}
                />
              </div>
              <div
                className="card-body"
                style={{ textAlign: 'center', marginBottom: '0' }}
              >
                <ul className="feature-list">
                  <li
                    key={`${state.active}-feature--key${2}`}
                    style={{ marginBottom: '0' }}
                  >
                    <Icon icon={checkmarkCircled} />
                    More flexible requirements for multifamily
                  </li>
                </ul>
              </div>
              <div className="card-footer" style={{ marginTop: '0' }}>
                <Button title={'Learn more'} onClick={goToPurchaseOrRefi} />
              </div>
            </PricingCard>
            <PricingCard>
              <div
                className="card-header"
                style={{ textAlign: 'center', marginBottom: '0' }}
              >
                <Heading as="h3" content={`Bridge and Mezzanine`} />
                <img
                  src={bridgeImage}
                  alt="Multifamily"
                  style={{ width: '100px', margin: '10px 0' }}
                />
              </div>
              <div
                className="card-body"
                style={{ textAlign: 'center', marginBottom: '0' }}
              >
                <ul className="feature-list">
                  <li
                    key={`${state.active}-feature--key${2}`}
                    style={{ marginBottom: '0' }}
                  >
                    <Icon icon={checkmarkCircled} />
                    Short term to stabilize multifamily/commercial properties
                  </li>
                </ul>
              </div>
              <div className="card-footer" style={{ marginTop: '0' }}>
                <Button title={'Learn more'} onClick={goToPurchaseOrRefi} />
              </div>
            </PricingCard>
          </InnerWrapper>
        </PricingArea>
        <PricingArea>
          <InnerWrapper>
            <PricingCard>
              <div
                className="card-header"
                style={{ textAlign: 'center', marginBottom: '0' }}
              >
                <Heading as="h3" content={`Private Money Loans`} />
                <img
                  src={multifamilyImage}
                  alt="Multifamily"
                  style={{ width: '95px', margin: '10px 0' }}
                />
              </div>
              <div
                className="card-body"
                style={{ textAlign: 'center', marginBottom: '0' }}
              >
                <ul className="feature-list">
                  <li
                    key={`${state.active}-feature--key${2}`}
                    style={{ marginBottom: '0' }}
                  >
                    <Icon icon={checkmarkCircled} />
                    Out of the box lending for all types
                  </li>
                </ul>
              </div>
              <div className="card-footer" style={{ marginTop: '0' }}>
                <Button title={'Learn more'} onClick={goToPurchaseOrRefi} />
              </div>
            </PricingCard>
          </InnerWrapper>
        </PricingArea>
      </Container>
    </SectionWrapper>
  );
};

const mapStateToProps = ({ root: { currentUser } }) => ({
  currentUser: currentUser?.user,
  userToken: currentUser?.token,
});

const mapDispatchToProps = (dispatch) => {
  return {
    updateUserInfo: (currentUser, userToken) =>
      dispatch(updateUserInfo(currentUser, userToken)),
  };
};

const OurProductsFinanceTypeSquaresRedux = connect(
  mapStateToProps,
  mapDispatchToProps
)(OurProductsFinanceTypeSquares);

export default OurProductsFinanceTypeSquaresRedux;
